<template>
  <Card class="w-full" shadow>
    <div class="mx-auto py-4">
      <div class="register-form" key="form">
        <form @submit.prevent="initiatePasswordReset">
          <Input
            type="email"
            placeholder="What's your email?"
            class="mt-10"
            v-model="email"
            width="w-full"
            errorText="Please enter a valid email address"
            :validation="rules.email"
            @valid="valid.email = $event"
          />
          <Button
            text="Continue"
            class="mt-10"
            :shadow="true"
            width="w-full"
            :loading="emailLoading"
            :disabled="disableButton"
            type="submit"
          />
        </form>
        <div class="mt-10">
          <p class="text-sm text-center text-black font">
            Did not forget your password?
            <router-link to="/login" class="text-brandPurple"
              >Login here</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
  // import Vue from "vue";
  import { mapActions, mapState } from "vuex";
  import { Input } from "@/UI/Input";
  import { Button, SecondaryButton } from "@/UI/Button";
  // import { OtpInput } from "@/UI/Input";
  // import VueCountdown from "@chenfengyuan/vue-countdown";
  // Vue.component("vue-countdown", VueCountdown);

  export default {
    components: {
      Input,
      Button,
      SecondaryButton,
      // Spinner: () => import("@/UI/Spinner"),
      // OtpInput,
    },
    data() {
      return {
        email: "",
        otp: "",
        // showMessage: true,
        loading: false,
        emailLoading: false,
        // resendLoading: false,
        valid: {
          email: false,
        },
        // otpValid: false,
        // showResendButton: false,
      };
    },
    computed: {
      ...mapState({
        id: (state) => state.auth.password_id,
      }),
      rules() {
        return {
          email:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              this.email?.trim()
            ),
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      ...mapActions("auth", [
        "forgotPassword",
        // "setOtpId",
        // "verifyPasswordResetOtp",
      ]),
      initiatePasswordReset() {
        this.emailLoading = true;
        this.forgotPassword({
          username: this.email?.trim(),
        })
          .then(() => {
            this.emailLoading = false;
            // this.handleDelayOtpButton();
            this.$router.push("/forgot-password-otp");
          })
          .catch(() => {
            this.emailLoading = false;
          });
      },
    },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  /* .register-container {
    min-height: 100vh;
  } */
  .register-container h3 {
    font-size: 35px;
  }
  .register-container p {
    line-height: 30.15px;
  }
  .container-register {
    max-width: 33.831rem;
  }
  .resend-otp {
    position: absolute;
    top: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    .register-container {
      min-height: calc(100vh - 15rem);
      max-width: 100%;
    }
  }
</style>
