var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Card", { staticClass: "w-full", attrs: { shadow: "" } }, [
    _c("div", { staticClass: "mx-auto py-4" }, [
      _c("div", { key: "form", staticClass: "register-form" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.initiatePasswordReset.apply(null, arguments)
              },
            },
          },
          [
            _c("Input", {
              staticClass: "mt-10",
              attrs: {
                type: "email",
                placeholder: "What's your email?",
                width: "w-full",
                errorText: "Please enter a valid email address",
                validation: _vm.rules.email,
              },
              on: {
                valid: function ($event) {
                  _vm.valid.email = $event
                },
              },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
            _c("Button", {
              staticClass: "mt-10",
              attrs: {
                text: "Continue",
                shadow: true,
                width: "w-full",
                loading: _vm.emailLoading,
                disabled: _vm.disableButton,
                type: "submit",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt-10" }, [
          _c(
            "p",
            { staticClass: "text-sm text-center text-black font" },
            [
              _vm._v(" Did not forget your password? "),
              _c(
                "router-link",
                { staticClass: "text-brandPurple", attrs: { to: "/login" } },
                [_vm._v("Login here")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }